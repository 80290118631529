import "./Footer.css";
import React from "react";

function Footer() {
    let ghAlt = "github link"
    let lnAlt = "linkedin link";
    let ghLink = "https://www.github.com/mkorzeniewski"
    let lnLink = "https://www.linkedin.com/in/mkorzeniewski/"

    return (
        <footer className="footer--social-media">
            <a href={ghLink}>
            <img src="/images/GitHub Icon.svg" className="footer--social-media--github" alt={ghAlt}/>
            </a>
            <a href={lnLink}>
            <img src="/images/Linkedin Icon.svg" className="footer--social-media--linkedin" alt={lnAlt}/>
            </a>
        </footer>
    );
}

export default Footer;
